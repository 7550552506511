export default {
    PLATFORMS: {
        TERM_FINANCE: 'TF',
        AXCEL_FINANCE: 'AF',
    },

    LOAN_TYPES: {
        PERSONAL: 1,
        BUSINESS: 2,
        CAR: 3,
    },

    CAR_LOAN_TYPES: {
        COLLATERAL: 10,
        PURCHASE: 20,
    },

    PERSONAL_INSURANCE_TYPES: {
        BASIC: {
            id: 'BASIC',
            label: 'TF Basic',
            percentage: {
                TT: 0.015,
                BB: 0.015,
                JM: 0.015,
                GY: 0.015,
                LC: 0.015,
                VC: 0.015,
                AG: 0.015,
                GD: 0.015,
            },
        },
        PRO: {
            id: 'PRO',
            label: 'TF Pro',
            percentage: {
                TT: 0.0175,
                BB: 0.0175,
                JM: 0.0175,
                GY: 0.0175,
                LC: 0.0175,
                VC: 0.0175,
                AG: 0.0175,
                GD: 0.0175,
            },
        },
    },

    STORES: {
        TEST: 1,
        '1_GY': 404,
        '1_TT': 2,
        '1_BB': 403,
        '1_JM': 405,
        '1_LC': 406,
        '1_AG': 932,
        '1_GD': 1162,
        '2_TT': 437,
        '2_BB': 586,
        '1_AG_AF': 1458,
        '1_BB_AF': 1166,
        '1_GD_AF': 1167,
        '1_LC_AF': 1168,
    },

    COUNTRIES: {
        TT: {
            key: 'TT',
            name: 'Trindad & Tobago',
            locale: 'tt',
            currency: 'TTD',
            areaCode: '868',
        },
        BB: {
            key: 'BB',
            name: 'Barbados',
            locale: 'bb',
            currency: 'BBD',
            areaCode: '246',
        },
        JM: {
            key: 'JM',
            name: 'Jamaica',
            locale: 'jm',
            currency: 'JMD',
            areaCode: '876',
        },
        GY: {
            key: 'GY',
            name: 'Guyana',
            locale: 'gy',
            currency: 'GYD',
            areaCode: '592',
        },
        LC: {
            key: 'LC',
            name: 'St. Lucia',
            locale: 'lc',
            currency: 'XCD',
            areaCode: '758',
        },
        VC: {
            key: 'VC',
            name: 'St. Vincent',
            locale: 'vc',
            currency: 'XCD',
            areaCode: '1',
        },
        AG: {
            key: 'AG',
            name: 'Antigua',
            locale: 'ag',
            currency: 'XCD',
            areaCode: '1',
        },
        GD: {
            key: 'GD',
            name: 'Grenada',
            locale: 'gd',
            currency: 'XCD',
            areaCode: '473',
        },
    },
};
