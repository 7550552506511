import SHARED_CONFIG from 'configs/shared';

export default {
    STEPS: {
        ESSENTIALS: {
            key: 'essentials',
            num: 1,
            labels: {
                title: "Let's get started",
                subtitle: 'Weclome to Axcel Finance. Please select your region and loan type to get started.',
            },
        },
        LOAN: {
            key: 'loan',
            num: 2,
            labels: {
                title: 'How much are you borrowing?',
                subtitle:
                    "Use our interactive loan calculator to ensure that you're comfortable with your monthly payments and proposed interest.",
            },
        },
        INSURANCE: {
            key: 'insurance',
            num: 3,
            labels: {
                title: "Got coverage? Let's talk insurance",
                subtitle:
                    'We live in a world where unfortunate circumstances arise. At Axcel Finance, we are looking to give you peace of mind.',
            },
        },
        DETAILS: {
            key: 'details',
            num: 4,
            labels: {
                title: 'Tell us about yourself',
                subtitle: "Let's start by getting to know each other a little better.",
            },
        },
        FINANCIALS: {
            key: 'financials',
            num: 5,
            labels: {
                title: 'Who do you bank with?',
                subtitle:
                    'We need to know your banking details so that we can send funds directly to your account once your loan is approved.',
            },
        },
        EMPLOYER: {
            key: 'employer',
            num: 6,
            labels: {
                title: 'Tell us about your employer',
                subtitle:
                    'We need to know where you work and how much you earn to ensure that the loan offered to you is responsible and affordable.',
            },
        },
        BUSINESS: {
            key: 'business',
            num: 6,
            labels: {
                title: 'Tell us about your business',
                subtitle:
                    "It's important for us to get information about your business to ensure that the loan offered to you is responsible and affordable.",
            },
        },
        CAR: {
            key: 'car',
            num: 7,
            labels: {
                title: 'Tell us about your vehicle',
                subtitle: 'Details about your vehicle helps us to get you the right amount of funding.',
            },
        },
        CREDENTIALS: {
            key: 'credentials',
            num: 8,
            labels: {
                title: 'Set up your login details',
                subtitle: 'Please create a username and password to access your membership portal.',
            },
        },
        REVIEW: {
            key: 'review',
            num: 9,
            labels: {
                title: 'Review your application',
                subtitle: "Let's ensure we've got everything right!",
            },
        },
        COMPLETE: {
            key: 'complete',
            num: 10,
            labels: {
                title: 'Welcome to Axcel Finance',
                subtitle: 'Congratulations! Your loan application was submitted successfully.',
            },
        },
    },

    CUSTOM_FIELDS: {
        OPERATIONS_START_DATE: 6,
        FULL_TIME_EMPLOYEES: 7,
        PART_TIME_EMPLOYEES: 8,
        AVERAGE_MONTHLY_SALES_REVENUE: 9,
        OUTSTANDING_LOANS: 10,
        USE_OF_FUNDS: 11,
        LOAN_DURATION: 12,
        PEP: 14,
        BUSINESS_TERMS_AND_CONDITIONS: 15,
        INSURANCE_TYPE: 18,
    },

    OPTIONS: {
        YES_NO: [
            {
                label: 'Yes',
                value: true,
            },
            {
                label: 'No',
                value: false,
            },
        ],
        GENDER: [
            {
                label: 'Male',
                value: 'Male',
            },
            {
                label: 'Female',
                value: 'Female',
            },
        ],
        COUNTRY: [
            // {
            //     label: SHARED_CONFIG.COUNTRIES.TT.name,
            //     value: SHARED_CONFIG.COUNTRIES.TT.key,
            //     isSME: false,
            //     isCar: false,
            // },
            {
                label: SHARED_CONFIG.COUNTRIES.BB.name,
                value: SHARED_CONFIG.COUNTRIES.BB.key,
                isSME: false,
                isCar: false,
            },
            // {
            //     label: SHARED_CONFIG.COUNTRIES.JM.name,
            //     value: SHARED_CONFIG.COUNTRIES.JM.key,
            // },
            // {
            //     label: SHARED_CONFIG.COUNTRIES.GY.name,
            //     value: SHARED_CONFIG.COUNTRIES.GY.key,
            // },
            {
                label: SHARED_CONFIG.COUNTRIES.LC.name,
                value: SHARED_CONFIG.COUNTRIES.LC.key,
                isSME: false,
                isCar: false,
            },
            // {
            //     label: SHARED_CONFIG.COUNTRIES.VC.name,
            //     value: SHARED_CONFIG.COUNTRIES.VC.key,
            // },
            {
                label: SHARED_CONFIG.COUNTRIES.GD.name,
                value: SHARED_CONFIG.COUNTRIES.GD.key,
                isSME: false,
                isCar: false,
            },
            {
                label: SHARED_CONFIG.COUNTRIES.AG.name,
                value: SHARED_CONFIG.COUNTRIES.AG.key,
                isSME: false,
                isCar: false,
            },
        ],
        ACCOUNT_TYPE: [
            {
                label: 'Checking',
                value: 'CheckingAccount',
            },
            {
                label: 'Savings',
                value: 'SavingsAccount',
            },
        ],
        INCOME_TYPE: [
            {
                label: 'Employment',
                value: 1,
            },
            {
                label: 'Social Security',
                value: 2,
            },
            {
                label: 'Disability',
                value: 3,
            },
            {
                label: 'Retirement',
                value: 4,
            },
            {
                label: 'Self Employed',
                value: 5,
            },
            {
                label: 'Other',
                value: 6,
            },
        ],
        PAYROLL_TYPE: [
            {
                label: 'Cash',
                value: 'Cash',
            },
            {
                label: 'Check',
                value: 'Check',
            },
            {
                label: 'Direct Deposit',
                value: 'Direct Deposit',
            },
        ],
        PAY_FREQUENCY: [
            {
                label: 'Weekly',
                value: 'Weekly',
            },
            {
                label: 'Bi-Weekly',
                value: 'BiWeekly',
            },
            {
                label: 'Monthly',
                value: 'Monthly',
            },
            {
                label: 'Twice Per Month',
                value: 'TwicePerMonth',
            },
        ],
        DAY_OF_WEEK: [
            {
                label: 'Monday',
                value: 'Monday',
            },
            {
                label: 'Tuesday',
                value: 'Tuesday',
            },
            {
                label: 'Wednesday',
                value: 'Wednesday',
            },
            {
                label: 'Thursday',
                value: 'Thursday',
            },
            {
                label: 'Friday',
                value: 'Friday',
            },
            {
                label: 'Saturday',
                value: 'Saturday',
            },
            {
                label: 'Sunday',
                value: 'Sunday',
            },
        ],
        DAY_OF_MONTH: [
            {
                label: 'Last Day',
                value: 'LastDay',
            },

            {
                label: 'Last Week Day',
                value: 'LastWeekDay',
            },
        ],
        NUM_EMPLOYEES: [
            {
                label: '0 to 10 Employees',
                value: '0 to 10 Employees',
            },
            {
                label: '10 to 50 Employees',
                value: '10 to 50 Employees',
            },
            {
                label: '50 to 100 Employees',
                value: '50 to 100 Employees',
            },
            {
                label: '100 to 500 Employees',
                value: '100 to 500 Employees',
            },
            {
                label: '500+ Employees',
                value: '500+ Employees',
            },
        ],
        LOAN_REASONS: [
            {
                label: 'Personal',
                value: 'Personal',
            },
            {
                label: 'Business',
                value: 'Business',
            },
            {
                label: 'Agricultural',
                value: 'Argricultural',
            },
            {
                label: 'Vehicle',
                value: 'Vehicle',
            },
            {
                label: 'Education',
                value: 'Education',
            },
            {
                label: 'Medical',
                value: 'Medical',
            },
            {
                label: 'Home Renovation',
                value: 'Home Renovation',
            },
            {
                label: 'Debt Consolidation',
                value: 'Debt Consolidation',
            },
            {
                label: 'Other',
                value: 'Other',
            },
        ],
        INSURANCE_TYPES: [
            {
                label: 'Third Party',
                value: 'Third Party',
            },
            {
                label: 'Full Comprehensive',
                value: 'Full Comprehensive',
            },
        ],
    },
};
